import { initFlowbite } from "flowbite";

addEventListener("turbo:before-stream-render", (event) => {
    const originalRender = event.detail.render;

    event.detail.render = function (streamElement) {
        originalRender(streamElement);
        initFlowbite();
    }
})
