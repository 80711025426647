import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offerings-units-matching"
export default class extends Controller {
  static targets = ['submitButton', 'coveredUnits', 'quantity']

  updateCoveredUnits() {
    let remainingUnits = this.#sumTotalUnits()
    this.submitButtonTarget.disabled = false

    this.coveredUnitsTargets.forEach((coveredUnitElement, index) => {
      const units = parseInt(coveredUnitElement.dataset.units)

      let value = Math.min(remainingUnits, units)
      remainingUnits -= value
      if (index === this.coveredUnitsTargets.length - 1) {
        value += remainingUnits
      }

      coveredUnitElement.textContent = value
      this.submitButtonTarget.disabled |= value !== units
      this.#setColorClass(coveredUnitElement, value, units)
    })
  }

  #sumTotalUnits() {
    return this.quantityTargets.reduce((sum, quantityElement) => {
      const units = parseInt(quantityElement.dataset.units) || 0
      const quantity = parseInt(quantityElement.value) || 0

      return sum + quantity * units
    }, 0)
  }

  #setColorClass(element, value, maxUnits) {
    element.classList.remove('text-amber-500', 'text-emerald-500', 'text-rose-500')

    if (value < maxUnits) {
      element.classList.add('text-amber-500')
    } else if (value === maxUnits) {
      element.classList.add('text-emerald-500')
    } else {
      element.classList.add('text-rose-500')
    }
  }
}
