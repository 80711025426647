import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-disable"
export default class extends Controller {
  static targets = ["submit"]
  static values = { alt: String }


  submit() {
    this.submitTarget.innerHTML = this.altValue
  }
}
