import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stepper"
export default class extends Controller {
  static targets = ["content", "form", "toggle", "text", "visibility"]
  static values = { activeMode: String }

  connect() {
    this.#hideContents();
    if (this.activeModeValue) {
      this.#unhideContent(this.activeModeValue);
      this.#scrollToForm(this.activeModeValue);
    }
  }

  select_mode(event) {
    this.#hideContents();
    this.#unhideContent(event.currentTarget.dataset.mode);
    this.scrollNext();
  }

  toggle_access_level(event) {
    this.toggleTargets.forEach(checkbox => {
      if (checkbox !== event.target) {
        checkbox.checked = !checkbox.checked;
      }
    });

    this.textTargets.forEach(textElement => {
      textElement.classList.toggle('text-neutral-800');
    });

    this.visibilityTargets.forEach(target => { this.#toggleVisibility(target); });
  }

  scrollPrevious() { this.element.scrollTo({ top: this.element.scrollTop - window.innerHeight }) }

  scrollNextInstant() { this.#scrollNextWithBehavior('instant') }

  scrollNext() { this.#scrollNextWithBehavior('smooth') }

  #hideContents() { this.contentTargets.forEach((el) => { el.classList.add("hidden") }) }

  #unhideContent(mode) { this.contentTargets.find((el) => el.dataset.mode == mode).classList.remove("hidden") }

  #scrollToForm(mode) { this.formTargets.find((el) => el.dataset.mode == mode).scrollIntoView({ behavior: 'instant' }) }

  #scrollNextWithBehavior(behavior) { this.element.scrollTo({ top: this.element.scrollTop + window.innerHeight, behavior: behavior }) }

  #toggleVisibility(element) {
    if (element.classList.contains("hidden")) {
      element.classList.remove("hidden");
      element.classList.add("flex");
    } else {
      element.classList.remove("flex");
      element.classList.add("hidden");
    }
  }
}
