import { Controller } from "@hotwired/stimulus"
import { Tabs } from "flowbite"

// Connects to data-controller="flowbite--tab-manager"
export default class extends Controller {
  static values = {
    tabIds: Array,
    tabType: String,
    defaultTab: String,
  }

  static targets = ["tabElement"]

  connect() {
    const tabElements = this.tabIdsValue.map((tab) => {
      return {
        id: tab,
        triggerEl: document.querySelector(`#${tab}-tab`),
        targetEl: document.querySelector(`#${tab}`),
      }
    })

    let options = { defaultTabId: this.defaultTabValue };

    if (this.tabTypeValue === "underline") {
      options["activeClasses"] = "text-primary-600 border-primary-600";
      options["inactiveClasses"] = "border-transparent hover:text-black hover:border-black dark:hover:text-white dark:hover:border-white";
    }

    this.tabs = new Tabs(this.tabElementTarget, tabElements, options);
  }
}
