import { Controller } from "@hotwired/stimulus"
import color from "tinycolor2"

// Connects to data-controller="thumbnail-generator"
export default class extends Controller {
  static values = {
    uid: String
  }

  connect() {
    const uniqueId = Math.floor(Math.random() * Date.now())

    this.generateGradient(this.uidValue)
      .then(gradient => {
        this.element.innerHTML = `
          <svg viewBox="0 0 80 80">
            <g>
              <defs>
                <linearGradient id="${uniqueId}" x1="0" y1="0" x2="1" y2="1">
                  <stop offset="0%" stop-color="${gradient.fromColor}" />
                  <stop offset="100%" stop-color="${gradient.toColor}" />
                </linearGradient>
              </defs>
              <rect fill="url(#${uniqueId})" x="0" y="0" width="80" height="80" />
            </g>
          </svg>
        `
      })
  }

  generateGradient(seed) {
    return this.hue(seed)
      .then(h => {
        const c1 = color({ h, s: 0.95, l: 0.5 })
        const second = c1.triad()[1].toHexString()

        return {
          fromColor: c1.toHexString(),
          toColor: second
        }
      })
  }

  hue(str) {
    return this.hash(str)
      .then(n => {
        return n % 360
      })
  }

  hash(str) {
    let sum = 0

    return crypto.subtle.digest(
      "SHA-1",
      new TextEncoder().encode(str)
    ).then(buffer => {
      for (const n of new Uint8Array(buffer)) {
        sum += n
      }

      return sum
    })
  }
}
