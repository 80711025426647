import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="stripe"
export default class extends Controller {
  static targets = ["paymentElement", "form", "error"]
  static values = {
    clientSecret: String,
    returnUrl: String
  }

  connect() {
    this.stripe = Stripe(this.stripeKey, { locale: "en" });
    this.elements = this.stripe.elements(this.stripeElementConfigs);

    this.paymentElement = this.elements.create("payment");
    this.paymentElement.mount(this.paymentElementTarget);

    this.paymentElement.addEventListener("change", () => { this.errorTarget.textContent = ""; })
  }

  async submit(event) {
    event.preventDefault();
    Rails.disableElement(this.formTarget);

    // Payment Intents
    if (this.clientSecretValue.startsWith("pi_")) {
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrlValue,
        },
      });

      this.errorTarget.textContent = error.message;
      this.enableForm();

      // Setup Intents
    } else {
      const { error } = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrlValue,
        },
      });

      this.errorTarget.textContent = error.message;
      this.enableForm();
    }
  }

  enableForm() {
    setTimeout(() => {
      Rails.enableElement(this.formTarget);
    }, 100);
  }

  get stripeKey() {
    return document.querySelector('meta[name="stripe-key"]').getAttribute("content");
  }

  get stripeElementConfigs() {
    let tailwind = {
      "white": "#FEFFFF",
      "black": "#4D4D4D",
      "gray-400": "#9CA3AF",
      "gray-500": "#6B7280",
      "border-dark": "#D4E4FE30",
      "border-light": "#CDCDCE",
      "background-dark": "#050506",
      "background-light": "#F5F5F5",
      "primary-500": "#FF985C",
      "primary-600": "#FF7421",
      "text-sm": {
        "font-size": "0.875rem",
        "line-height": "1.25rem",
      },
      "font-medium": "500",
      "tracking-tight": "-0.025em",
      "p-2.5": "0.625rem",
      "mb-2": "0.5rem",
      "space-y-4": "1rem",
      "rounded-none": "0px",
    };
    let isDark = true;

    return {
      appearance: {
        theme: "stripe",
        variables: {
          colorBackground: isDark ? tailwind["background-dark"] : tailwind["background-light"],
          colorText: isDark ? tailwind["white"] : tailwind["black"],
          colorTextSecondary: isDark ? tailwind["white"] : tailwind["black"],
          colorTextPlaceholder: isDark ? tailwind["gray-500"] : tailwind["gray-400"],
          gridRowSpacing: tailwind["space-y-4"],
          fontFamily: "Geist Sans, Inter, ui-sans-serif, system-ui, -apple-system, system-ui, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
          borderRadius: tailwind["rounded-none"],
        },
        rules: {
          ".Input": {
            border: `1px solid ${isDark ? tailwind["border-dark"] : tailwind["border-light"]}`,
            boxShadow: "none",
            padding: tailwind["p-2.5"],
            fontSize: tailwind["text-sm"]["font-size"],
            lineHeight: tailwind["text-sm"]["line-height"],
            letterSpacing: tailwind["tracking-tight"],
          },
          ".Input:focus": {
            boxShadow: "none",
            border: `1px solid ${isDark ? tailwind["primary-500"] : tailwind["primary-600"]}`
          },
          ".Label": {
            marginBottom: tailwind["mb-2"],
            fontSize: tailwind["text-sm"]["font-size"],
            lineHeight: tailwind["text-sm"]["line-height"],
            fontWeight: tailwind["font-medium"],
            letterSpacing: tailwind["tracking-tight"],
          },
          ".TermsText": {
            letterSpacing: tailwind["tracking-tight"],
          }
        }
      },
      fonts: [
        {
          family: "Geist Sans",
          style: "normal",
          weight: "400",
          src: "url(https://cdn.jsdelivr.net/npm/@fontsource/geist-sans/files/geist-sans-latin-400-normal.woff)",
        },
        {
          family: "Geist Sans",
          style: "normal",
          weight: "500",
          src: "url(https://cdn.jsdelivr.net/npm/@fontsource/geist-sans/files/geist-sans-latin-500-normal.woff)",
        },
      ],
      clientSecret: this.clientSecretValue
    }
  }
}
